// styles
import style from "./InstructorCard.module.scss";

// assets
import E_RYT_200 from "../../assets/images/common/logo-E-RYT-200.png";

// component
const InstructorCard = (data) => {
  return (
    <>
      <h3 className={style.title}>
        {data.name}
        <small>{data.name_en}</small>
      </h3>

      <div className={style.photo}>
        <div className={style.inner}>
          <img
            src={data.thumbnail}
            width="300"
            height="360"
            alt=""
            loading="lazy"
            aria-hidden="true"
            className={style.image}
          />

          {data.certification.e_ryt_200 && (
            <img
              className={style.certification}
              src={E_RYT_200}
              alt="E-RYT-200 자격 증명"
            />
          )}
        </div>
      </div>

      <div className={style.introduce}>
        <ol>
          <li>
            <strong>Qualifications</strong>

            <ul>
              {data.qualifications.map((item, index) => {
                return (
                  <li
                    key={index}
                    dangerouslySetInnerHTML={{ __html: item }}
                  ></li>
                );
              })}
            </ul>
          </li>

          <li>
            <strong>Career</strong>

            <ul>
              {data.career.map((item, index) => {
                return <li key={index}>{item}</li>;
              })}
            </ul>
          </li>
        </ol>
      </div>

      <div
        className={style.message}
        dangerouslySetInnerHTML={{ __html: data.message }}
      />
    </>
  );
};

export default InstructorCard;
