// import
import PageMeta from "../../functions/PageMeta/PageMeta";
import InstructorCard from "../../components/InstructorCard/InstructorCard";

// assets
import style from "./Instructor.module.scss";

import InstuctorProfileImage01 from "../../assets/images/instructor/profile01.jpg";
import InstuctorProfileImage02 from "../../assets/images/instructor/profile02.jpg";
import InstuctorProfileImage03 from "../../assets/images/instructor/profile03.jpg";
import InstuctorProfileImage04 from "../../assets/images/instructor/profile04.jpg";

const InstructorProfiles = [
  {
    name: "도예린 원장",
    name_en: "Yerin DO",
    thumbnail: InstuctorProfileImage01,
    certification: {
      e_ryt_200: true,
    },
    qualifications: [
      "- 숭실대학교 평생교육학과 학사",
      "- 평생교육사 자격증 2급",
      "- Yoga Alliance 인증 국제요가강사지도자 e-RYT 200",
      "- Yogahimsa Yoga Instructor Training",
      "- 굿스파인재활필라테스협회 필라테스 지도자 자격증",
      "- 굿스파인재활필라테스협회 재활전문가 자격증",
    ],
    career: [
      "- 아이엔지핫요가 강사",
      "- 요가힘사 강사",
      "- 래미안자이아파트 커뮤니티센터 강사",
      "- 강남구민체육회관 강사",
      "- 레드콩고 휘트니스 요가/필라테스 강사",
      "- 파워휘트니스 요가/필라테스 강사",
      "- 로얄비전헬스센터 요가/필라테스 강사",
    ],
    message: `“나는 나 자신과 직면할 수 있을까?
              <br />날것의 나를 바라볼 수 있을까?
              <br />나를 사랑한다는 건 어떤 것일까?
              <br />이런 고민을 안고 요가와 필라테스를 시작했어요.
              <br />
              <br />‘나를 사랑한다는 건 이런 거구나!’를 깨닫고
              <br />현재를 그렇게 살려고 노력하면서 살고 있어요.
              <br />그 마음을 다른 분들과 나누고 싶어 지금의 공간을 꾸렸어요.
              <br />
              <br />아이나 요가&필라테스를 찾아주시는 모든 분들이
              <br />스스로를 바라보고, 사랑하고, 그 사랑을 주변 사람들과 나누며 살아가기를
              <br />빛이 거울(아이나)에 반사되듯 서로가 서로를 비추는 빛이 되기를 바라는 마음입니다.”`,
  },
  {
    name: "박혜수",
    name_en: "Hyesoo Park",
    thumbnail: InstuctorProfileImage02,
    certification: {
      e_ryt_200: false,
    },
    qualifications: [
      "- 꿈쿠라 요가 아카데미 아헹가 요가 지도자 자격증",
      "- Earth yoga school 체어 요가 지도자 자격증 (Chiang Mai, Thailand)",
      "- Korea Yoga Pilates Association 컨템포러리&클래식 매트 필라테스 지도자 자격증",
      "- 국제통합테라피학회 월요가 지도자 자격증",
      "- 워크숍 수강<br /><small>(KYPA 아헹가 요가 핸즈온, KYPA 근골격계 해부학, 공인티쳐 Lex의 아헹가 요가, 꿈쿠라 요가 아카데미 아쉬탕가 프라이머리)</small>",
    ],
    career: [
      "- 필라테스&플라잉요가 Ann 요가 강사",
      "- 요가라테스 광명점 요가 강사",
      "- 바른몸 메디 월요가 필라테스 요가 강사",
      "- 마이필라테스N요가 요가 강사",
    ],
    message: `“요가 수련 이외에도 음양오행과 경락을 공부하고 있습니다.
              <br />묘기 같은 동작보다는 우리 몸의 정렬을 잘 맞춰 건강하게 사는 것에 초점을 두어 요가를 안내합니다.
              <br />
              <br />밝은 미소로 편안함과 행복함을 선사해 드릴게요.”`,
  },
  {
    name: "이나윤",
    name_en: "Nayoon Lee",
    thumbnail: InstuctorProfileImage03,
    certification: {
      e_ryt_200: false,
    },
    qualifications: [
      "- 요가쿨라 힐링요가 지도자 자격증",
      "- 요가쿨라 프리야빈야사 지도자 자격증",
      "- Yoga alliance RYT200",
      "- 소도구 메디컬 코리아 해부학 과정 이수",
      "- 김동진 아쉬탕가 워크샵 수료",
      "- 삶은요가 테라피요가 지도자 자격증",
      "- 로카요가 인요가 지도자 자격증",
    ],
    career: [
      "- 피트니스 바이블 요가 강사",
      "- 브레스 요가 스튜디오 요가강사",
      "- 라인짐 휘트니스 요가 강사",
      "- 짐브라운 요가&필라테스 요가강사",
    ],
    message: `“우리는 온전히 나로 있을때 평안함을 느낍니다.
              <br />수많은 외부 자극으로 흐려진 ‘나’를 선명하게 보기위해 오늘도 매트 위에서 요가를 합니다.
              <br />호흡을 통해 지금, 여기에 있음을 느끼고 견고하고 자유로운 움직임을 통해 몸의 감각을 깨워갑니다.
              <br />
              <br />매트 위의 소중한 여정에 언제나 함께 하겠습니다.”`,
  },
  {
    name: "이세영",
    name_en: "Seyoung Lee",
    thumbnail: InstuctorProfileImage04,
    certification: {
      e_ryt_200: false,
    },
    qualifications: [
      "- 사트얌요가 Lv.1 빈야사 지도자 자격증",
      "- Yoga Alliance인증 국제요가강사지도자 RYT 200",
      "- 여성과 요가 워크숍",
      "- 요가해부학 워크숍",
      "- 인요가 워크숍",
      "- 매트필라테스 워크숍",
    ],
    career: ["- 이촌 스포하이 피트니스 요가강사"],
    message: `“요가를 하면서 점점 더 나 자신을 사랑하게 되었습니다.
              <br />요가를 하는 동안 나타나는 습관과 생각들은 우리 삶에 그대로 반영된다는 점에서, <br class='br-pc' />매트 위의 움직임은 우리 삶의 작은 축소판과 같습니다.
              <br />자신의 호흡과 움직임에 온전한 집중을 하다 보면 불안, 걱정, 그리고 타인과의 비교를 내려놓을 수 있습니다.
              <br />
              <br />요가를 하는 매 순간순간, 여러분의 마음에 평온함이 전해질 수 있도록 안내하겠습니다.”`,
  },
];

// Instructor
function Instructor() {
  return (
    <>
      <PageMeta where={location} />

      <main id={style.instructor}>
        <header className={style["sec-header"]}>
          <div className="container">
            <h2 className="common-headline">Instructor</h2>
          </div>
        </header>

        <section className={style["sec-instructor"]}>
          <div className="container">
            <ol className={style["instructor-list"]} role="list">
              {InstructorProfiles.map((data, index) => {
                return (
                  <li key={index} role="listitem">
                    <InstructorCard {...data}></InstructorCard>
                  </li>
                );
              })}
            </ol>
          </div>
        </section>
      </main>
    </>
  );
}

export default Instructor;
