// import
import PageMeta from "../../functions/PageMeta/PageMeta";
import NaverCounselButton from "../../components/NaverCounselButton/NaverCounselButton";

// assets
import style from "./Classes.module.scss";

import PrivateImage01 from "../../assets/images/classes/private-photo01.jpg";
import PrivateImage02 from "../../assets/images/classes/private-photo02.jpg";
import PrivateImage03 from "../../assets/images/classes/private-photo03.jpg";
import PrivateImage04 from "../../assets/images/classes/private-photo04.jpg";
import PrivateImage05 from "../../assets/images/classes/private-photo05.jpg";

import GroupImage01 from "../../assets/images/classes/group-photo01.jpg";
import GroupImage02 from "../../assets/images/classes/group-photo02.jpg";
import GroupImage03 from "../../assets/images/classes/group-photo03.jpg";
import GroupImage04 from "../../assets/images/classes/group-photo04.jpg";
import GroupImage05 from "../../assets/images/classes/group-photo05.jpg";

import CoupleImage01 from "../../assets/images/classes/couple-photo01.jpg";
import CoupleImage02 from "../../assets/images/classes/couple-photo02.jpg";
import CoupleImage03 from "../../assets/images/classes/couple-photo03.jpg";
import CoupleImage04 from "../../assets/images/classes/couple-photo04.jpg";
import CoupleImage05 from "../../assets/images/classes/couple-photo05.jpg";
import { BOOKING } from "../../config";

// 개인 레슨
function Private() {
  const images = [
    { url: PrivateImage01 },
    { url: PrivateImage02 },
    { url: PrivateImage03 },
    { url: PrivateImage04 },
    { url: PrivateImage05 },
  ];

  return (
    <>
      <PageMeta where={location} />

      <div className={style.private}>
        <div className="container">
          <div className={style.message}>
            <p>
              요가와 필라테스 중 무엇을 배울지 고민되시나요?
              <br />
              <br />
              어떤 운동을 선택하든, 컨디션에 맞춘 퍼스널 케어를 받아보세요.
              <br />
              <strong>필라테스 수업, 요가 수업, 요가&필라테스 혼합 수업</strong>
              을 횟수에 따라 선택할 수 있어요.
              <br />
              <small>(예: 요가 5회 + 필라테스 5회)</small>
              <br />
              <br />
              요가와 필라테스 모두 1:1로 원장 선생님이 직접 지도합니다.
            </p>

            <NaverCounselButton url={BOOKING.PRIVATE} />
          </div>

          <div className={style.photos}>
            {images.map((item, index) => {
              return (
                <img key={index} src={item.url} alt="" aria-hidden="true" />
              );
            })}
          </div>

          <div className={style.classes}>
            <h3 className="common-headline">Yoga</h3>

            <ul className={style.list}>
              <li>
                <strong>아쉬탕가 요가</strong>

                <p>
                  전통적인 요가 중 하나로, 연속적인 호흡과 동적인 움직임을 통해
                  정신과 몸의 조화를 추구합니다.
                </p>
              </li>

              <li>
                <strong>베이직 빈야사</strong>

                <p>
                  물 흐르듯 이어지는 플로우 속에서 유연성과 근력을 모두 강화하고
                  균형 감각을 기를 수 있습니다. 기초 요가 자세를 기반으로
                  다양하고 자유로운 형태의 움직임으로 뻗어나갑니다.
                </p>
              </li>

              <li>
                <strong>코어&사이드</strong>

                <p>
                  몸의 중심을 이루는 코어와 사이드 코어 근력을 단련하여 바르고
                  균형 잡힌 자세를 찾아갑니다. 크로스핏을 접목한 유산소 운동으로
                  체력을 증진하고 근지구력을 향상시킵니다.
                </p>
              </li>

              <li>
                <strong>테라피</strong>

                <p>
                  깊은 이완과 휴식을 위한 요가입니다. 긴 호흡과 충분한 시간을
                  들여 스트레스를 이완하고 몸을 개운하게 만들어요.
                </p>
              </li>

              <li>
                <strong>밸런스 요가</strong>

                <p>
                  비대칭을 개선하고 균형 감각을 깨우기 위한 요가입니다. 좌우
                  감각의 차이를 느끼고 균형을 잡아가는 것에 집중합니다.
                </p>
              </li>

              <li>
                <strong>시니어 요가</strong>

                <p>
                  천천히 움직이는 동작을 통해 관절 유연성을 향상시키고, 기초
                  근력을 강화하여 일상생활에 활력을 줍니다. 몸과 마음의 연결을
                  통해 안정감을 증진시킵니다.
                </p>
              </li>
            </ul>
          </div>

          <div className={style.classes}>
            <h3 className="common-headline">Pilates</h3>

            <ul className={style.list}>
              <li>
                <strong>다이어트</strong>

                <p>
                  전신의 체지방을 태우는 유산소 운동과 근력 운동을 병행하여
                  지방을 감량하고 균형 잡힌 몸을 만듭니다. 특히 복부 지방을
                  감소시키고 건강하고 탄력 있는 몸을 만듭니다.
                </p>
              </li>

              <li>
                <strong>체형 교정</strong>

                <p>
                  거북목, 굽은 어깨, 굽은 등, 휜 다리 등 불균형과 비대칭을
                  교정합니다. 바른 자세를 인지하고 스스로 유지할 수 있는 힘을
                  기릅니다.
                </p>
              </li>

              <li>
                <strong>근력 강화</strong>

                <p>
                  코어 근력 강화를 통한 신체 안정성과 균형을 찾고, 신체에
                  탄력성과 활력을 증진합니다. 다른 운동을 병행하시는 분들께 균형
                  있는 신체 발달을 위해 추천합니다.
                </p>
              </li>

              <li>
                <strong>재활</strong>

                <p>
                  디스크 또는 척추 질환의 수술이나 시술 후 빠른 일상생활 복귀와
                  정상적 기능 회복을 위한 프로그램입니다.
                </p>
              </li>

              <li>
                <strong>시니어 클래스</strong>

                <p>
                  오십견, 골다공증 등을 예방, 관리하고 만성 통증을 감소시킵니다.
                  수술 후 일상생활에의 빠른 복귀와 정상적 기능 회복을 돕습니다.
                </p>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}

// 소그룹 요가
function Group() {
  const images = [
    { url: GroupImage01 },
    { url: GroupImage02 },
    { url: GroupImage03 },
    { url: GroupImage04 },
    { url: GroupImage05 },
  ];

  return (
    <>
      <PageMeta where={location} />

      <div className={style.group}>
        <div className="container">
          <div className={style.message}>
            <p>
              개인 레슨보다 저렴한 비용, 그룹 레슨보다 집중적인 지도
              <br />
              요가와 필라테스의 완벽한 시너지를 소그룹 수업으로 경험해보세요.
              <br />
              <br />
              <strong>소수정예 집중 케어</strong> : 최대 6명 정원의 소그룹
              수업으로 개인별 맞춤형 집중 지도를 받으세요.
              <br />
              <strong>폐강 없는 수업</strong> : 예약 1명만 있어도 무조건
              진행됩니다. 최소 정원이 없어 폐강 걱정이 없어요.
              <br />
              <strong>올 패스 이용권</strong> : 하나의 수강권으로 요가와
              필라테스를 동시에 이용할 수 있어요.
              <br />
              <br />
              “소수로 진행하니 다른 사람들이 덜 신경쓰이고 집중이 잘 돼요.”
              <br />
              “선생님이 한 명 한 명 꼼꼼하게 더 잘 봐주셔서 좋아요.”
              <br />
              “요가와 필라테스를 골라서 들을 수 있어서 좋아요.”
            </p>

            <NaverCounselButton url={BOOKING.GROUP} />
          </div>

          <div className={style.photos}>
            {images.map((item, index) => {
              return (
                <img key={index} src={item.url} alt="" aria-hidden="true" />
              );
            })}
          </div>

          <div className={style.classes}>
            <div className={`${style.title} common-headline`}>Group Class</div>

            <ul className={style.list}>
              <li>
                <strong>하타 / Hatha</strong>

                <p>
                  하타요가에서 하(Ha)는 태양을, 타(Tha)는 달을 의미합니다.
                  ‘음양의 조화’ 라는 뜻을 가지고 있습니다. 수련을 통해 육체와
                  정신의 조화와 균형을 이루는 요가로, 한 동작을 오랜 시간
                  유지하는 특징이 있습니다.
                </p>
              </li>
              <li>
                <strong>아헹가 / Iyengar yoga</strong>

                <p>
                  여러 요가를 집대성하고 의학과 과학을 접목해 현대화시킨
                  요가입니다. 블럭, 스트랩, 벽 등의 보조도구를 사용하여 안정되고
                  정확한 아사나를 할 수 있도록 도와줍니다.
                </p>
              </li>
              <li>
                <strong>빈야사 / Vinyasa</strong>

                <p>
                  물 흐르듯 이어지는 플로우 속에서 유연성과 근력을 모두 강화하고
                  균형 감각을 기를 수 있습니다. 기초 요가 자세를 기반으로
                  다양하고 자유로운 형태의 움직임으로 뻗어나갑니다.
                </p>
              </li>
              <li>
                <strong>테라피 / Therapy</strong>

                <p>
                  깊은 이완과 휴식을 위한 요가입니다. 긴 호흡과 충분한 시간을
                  들여 스트레스를 이완하고 몸을 개운하게 만듭니다. 신체
                  불균형으로 인한 통증 관리와 재활에 효과적입니다.
                </p>
              </li>
              <li>
                <strong>밸런스 필라테스 / Balance Pilates</strong>

                <p>
                  신체의 전반적인 균형을 회복하고 근육의 피로를 효과적으로
                  해소하는 것을 목표로 합니다. 자세 교정과 근육 불균형 해소에
                  초점을 맞춰, 반복된 일상 속 긴장을 풀어주고 몸의 컨디션을
                  상쾌하게 만들어 줍니다.
                </p>
              </li>
              <li>
                <strong>코어 필라테스 / Core Pilates</strong>

                <p>
                  몸의 중심인 코어 근육을 강화하는 데 중점을 둔 프로그램입니다.
                  코어 집중 운동과 동적인 움직임을 결합하여, 신체의 중심을
                  단단하게 잡아주고 일상생활에서의 자세와 체형 개선을 돕습니다.
                </p>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}

// 커플 요가
function Couple() {
  const images = [
    { url: CoupleImage01 },
    { url: CoupleImage02 },
    { url: CoupleImage03 },
    { url: CoupleImage04 },
    { url: CoupleImage05 },
  ];

  return (
    <>
      <PageMeta where={location} />

      <div className={style.couple}>
        <div className="container">
          <div className={style.message}>
            <p>
              사랑하는 사람의 호흡과 심장 소리를 들어보세요.
              <br />
              서로 호흡을 맞춰가면서 연결 되어 있음을 느껴보세요.
              <br />
              <br />
              <strong>요가가 처음이어도, 뻣뻣해도 할 수 있어요.</strong>
              <br />두 분의 몸 상태에 맞는 난이도로 구성됩니다.
            </p>

            <NaverCounselButton url={BOOKING.COUPLE} />
          </div>

          <div className={style.photos}>
            {images.map((item, index) => {
              return (
                <img key={index} src={item.url} alt="" aria-hidden="true" />
              );
            })}
          </div>

          <div className={style.classes}>
            <div className={`${style.title} common-headline`}>
              커플 요가, 이런 점이 좋아요
            </div>

            <ul className={style.list}>
              <li>서로에 대한 신뢰와 유대감을 강화해요.</li>
              <li>
                함께 요가를 하면서 스트레스를 줄이고 마음의 평화를 찾을 수
                있어요.
              </li>
              <li>서로의 감정을 더 잘 이해하고 공감하는 능력을 키워줘요.</li>
            </ul>

            <p className={style.description}>
              서로의 손을 맞잡을 때, 우리는 더 강해지고, 더 행복해지며, 더 깊은
              사랑을 느낍니다.
              <br />
              오늘, 사랑하는 사람과 특별한 데이트를 해보는건 어떠세요?
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

// ClassesContent
function ClassesContent(data) {
  const now = data.content.now;

  switch (now) {
    case "private":
      return <Private />;

    case "couple":
      return <Couple />;

    case "group":
      return <Group />;
  }
}

export default ClassesContent;
