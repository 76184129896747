// import
// import { useState } from "react";
import PropTypes from "prop-types";
import { EffectFade, Autoplay } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import BannerCard from "./BannerCard";

// swiper
import "swiper/scss";
import "swiper/scss/effect-fade";
import "swiper/scss/autoplay";

// assets
import style from "./Banner.module.scss";

import LLLCardLogo from "../../assets/images/banner/lllcard-sticker.png";
import LogoSymbol from "../../assets/svgs/logo-symbol-white.svg";
import LogoKrFull from "../../assets/svgs/logo-kr-full-white.svg";
import { ReactComponent as IcoClose } from "../../assets/svgs/icon-close.svg";

// component
function Banner({ onClose }) {
  return (
    <div className={style.banner}>
      <Swiper
        className={style.swiper}
        modules={[EffectFade, Autoplay]}
        slidesPerView={1}
        effect={"fade"}
        fadeEffect={{ crossFade: true }}
        autoHeight
        loop
        autoplay={{
          delay: 2500,
          disableOnInteraction: true,
          pauseOnMouseEnter: true,
        }}
      >
        <SwiperSlide>
          <BannerCard
            className={style.lllcard}
            logoSrc={{ desktop: LLLCardLogo }}
            link="https://naver.me/GvcOd1Uv"
            label="평생교육바우처 사용기관"
            description="아이나 요가&필라테스에서는 평생교육바우처를 사용하여 다양한 수업을 수강하실 수 있습니다."
            linkText="자세히 보기"
          />
        </SwiperSlide>

        <SwiperSlide>
          <BannerCard
            className={style.review}
            logoSrc={{ desktop: LogoKrFull, mobile: LogoSymbol }}
            link="https://naver.me/502hKhPw"
            label="리뷰 쓰고 토삭스 받자!"
            description="SNS에 리뷰를 남겨주시면 요가&필라테스 토삭스를 드려요."
            linkText="리뷰 남기러 가기"
          />
        </SwiperSlide>
      </Swiper>

      <div className={`container ${style["close-button"]}`}>
        <button
          className={`${style.button}`}
          type="button"
          aria-label="이벤트 배너 닫기"
          onClick={onClose}
        >
          <IcoClose />
        </button>
      </div>
    </div>
  );
}

Banner.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default Banner;
